<template>
  <table class="table">
    <tr>
      <th>Name</th>
      <td>{{fulfilment.Data.primary_contact.primary_contact_title}} {{fulfilment.Data.primary_contact.primary_contact_first_name}} {{fulfilment.Data.primary_contact.primary_contact_last_name}}</td>
    </tr>
    <tr>
      <th>Email</th>
      <td>{{fulfilment.Data.primary_contact.primary_contact_email}}</td>
    </tr>
    <tr>
      <th>Product</th>
      <td>{{fulfilment.ProductName}}</td>
    </tr>
    <tr>
      <th>Sub type</th>
      <td>{{fulfilment.Data.products_info.offer_misc.offer_type}}</td>
    </tr>
    <tr>
      <th>Order Placed</th>
      <td>{{fulfilment.Data.meta_info.transaction_datetime}}</td>
    </tr>
    <tr>
      <th>Jellyfish Fulfilment Id</th>
      <td>{{fulfilment.Id}}</td>
    </tr>
    <tr v-if="fulfilment.BatchJobInfo">
      <th>Sent to bureau</th>
      <td>{{fulfilment.BatchJobInfo.RunAt}}</td>
    </tr>
    <tr v-if="fulfilment.BatchJobInfo && fulfilment.BatchJobInfo.Filename">
      <th>Filename</th>
      <td>{{fulfilment.BatchJobInfo.Filename}}</td>
    </tr>
    <tr>
      <th>Billing postcode</th>
      <td>{{fulfilment.Data.billing_contact.billing_contact_postcode}}</td>
    </tr>
    <tr>
      <th>Delivery  postcode</th>
      <td>{{fulfilment.Data.recipient_contacts.recipient_contact_postcode}}</td>
    </tr>
  </table>
</template>
 
<script>
export default {
  name: "fulfilment-info-for-bureau",
  props: {
    fulfilment: {
      type: Object,
      required: true
    }
  }
};
</script>